

























import { Component, Vue, Prop } from 'vue-property-decorator'

import File from '@/components/files/File.vue'
import Textarea from '@/components/inputs/Textarea.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import UserImage from '@/components/user/UserImage.vue'
import FileWrapper from '@/components/files/FileWrapper.vue'
import FileUploadButton from '@/components/inputs/FileUploadButton.vue'

import { QuestionResource } from '@/models/implementations/questions/QuestionResource'
import { QuestionAnswerRequest } from '@/requests/implementations/questions/QuestionAnswerRequest'
import { QuestionAnswerPatchRequest } from '@/requests/implementations/questions/QuestionAnswerPatchRequest'

@Component({
  components: {
    File,
    Textarea,
    UserImage,
    SmallLoader,
    FileWrapper,
    FileUploadButton
  },
})
export default class QuestionAnswerForm extends Vue {

  @Prop()
  private question!: QuestionResource

  @Prop()
  private entity_id?: number

  @Prop()
  private proposal_id?: number

  private fileTypes: string[] = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'text/csv',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]

  private form: QuestionAnswerRequest | QuestionAnswerPatchRequest = this.question.answer ? new QuestionAnswerPatchRequest(this.question.answer) : new QuestionAnswerRequest()
  private errors: ErrorResponse = {}

  private isSubmitting: boolean = false

  private get files(): Array<{ name: string, url?: string }> {
    return this.form.proof.map((file) => {
      return {
        name: file.name
      }
    })
  }

  private get exisitingFiles(): Array<{ name: string, url?: string }> {
    if (!('existing_files' in this.form)) return []
    return this.form.existing_files
  }

  private addFiles(files: FileList): void {
    if (!this.form) return

    if (files) {
      Array.from(files).forEach((file) => {
        if (this.form) {
          this.form.proof.push(file)
        }
      })
    }
  }

  private deleteUploadingFile(index: number) {
    this.form.proof.splice(index, 1)
  }

  private deleteExistingFile(index: number) {
    if (!('existing_files' in this.form)) return
    this.form.existing_files.splice(index, 1)
  }

  private async answerQuestion(relevance: boolean): Promise<void> {
    if (!this.proposal_id || !this.entity_id) return
    if (!this.form?.isValid) return

    this.isSubmitting = true
    this.form.relevance = relevance
    try {
      this.$emit('submitted')
      await this.question.answerQuestion({ body: this.form, proposal_id: this.proposal_id, entity_id: this.entity_id })
    } catch (e) {
      if (e.errors) {
        this.errors = e.errors
      }
    } finally {
      this.isSubmitting = false
    }
  }

}
