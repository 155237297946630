export class QuestionAnswerRequest {
  public message: string = ''
  public relevance: boolean = false
  public proof: File[] = []

  public get isValid(): boolean {
    return !!this.message
  }

  public get requestBody(): FormData {
    const formData = new FormData()
    formData.append('message', this.message)
    formData.append('relevance', this.relevance.toString())
    for (const file of this.proof) {
      formData.append('proof[]', file)
    }
    return formData
  }
}
