export class QuestionPatchRequest {
  public question: string = ''

  constructor(question?: string) {
    this.question = !!question ? question : ''
  }

  public get isValid(): boolean {
    return !!this.question
  }
}
