





















import { Component, Vue, Prop } from 'vue-property-decorator'

import File from '@/components/files/File.vue'
import UserImage from '@/components/user/UserImage.vue'
import FileWrapper from '@/components/files/FileWrapper.vue'

import { QuestionResource } from '@/models/implementations/questions/QuestionResource'
import { UserResource } from '@/models/users/UserResource'
import { AuthUserResource } from '@/models/users/AuthUserResource'
import { userModule } from '@/store'

@Component({
  components: {
    File,
    UserImage,
    FileWrapper
  },
})
export default class QuestionAnswerForm extends Vue {

  @Prop()
  private question!: QuestionResource

  private get currentUser(): AuthUserResource | undefined {
    return userModule?.user
  }

  private get canAnswerQuestion(): boolean {
    return this.question.canPerformAction('can_answer_question')
  }

  private get files(): Array<{ name: string, url?: string }> {
    return this.question.answer?.proof ?? []
  }

  private editQuestion() {
    this.$emit('update')
  }

  private isLoggedInUser(user: UserResource): boolean {
    if (this.currentUser) {
      return this.currentUser.id === user.id
    }

    return false
  }

}
