import { AnswerResource } from '@/models/implementations/questions/AnswerResource'

export class QuestionAnswerPatchRequest {
  public message: string = ''
  public relevance: boolean = false
  public proof: File[] = []
  public existing_files: Array<{ name: string, url: string }> = []

  constructor(answer: AnswerResource) {
    this.message = answer.message
    this.relevance = answer.relevance
    this.existing_files = answer.proof ? [...answer.proof] : []
  }

  public get isValid(): boolean {
    return !!this.message
  }

  public get requestBody(): FormData {
    const formData = new FormData()
    formData.append('message', this.message)
    formData.append('relevance', this.relevance.toString())
    for (const [index, file] of this.existing_files.entries()) {
      formData.append(`existing_files[${index}][name]`, file.name)
      formData.append(`existing_files[${index}][url]`, file.url)
    }
    for (const file of this.proof) {
      formData.append('proof[]', file)
    }
    return formData
  }
}
