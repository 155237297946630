




























































import { Component, Vue, Prop } from 'vue-property-decorator'

import Modal from '@/components/modals/Default.vue'
import Dots from '@/components/widgets/DotDotDot.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import TextInput from '@/components/inputs/Text.vue'

import QuestionAnswer from './QuestionAnswer.vue'
import QuestionAnswered from './QuestionAnswered.vue'

import { QuestionResource } from '@/models/implementations/questions/QuestionResource'
import { QuestionPatchRequest } from '@/requests/implementations/questions/QuestionPatchRequest'

@Component({
  components: {
    Dots,
    Modal,
    TextInput,
    SmallLoader,
    QuestionAnswer,
    QuestionAnswered,
  },
})
export default class QuestionListItem extends Vue {
  @Prop()
  private question!: QuestionResource

  @Prop()
  private entity_id?: number

  @Prop()
  private proposal_id?: number

  private isDeleting: boolean = false
  private isEditing: boolean = false
  private isAnswerOpen: boolean = false

  private updateQuestionForm: ModalForm<QuestionPatchRequest> = {
    isOpen: false,
    isSubmitting: false,
    form: new QuestionPatchRequest(),
    errors: {}
  }

  private get isEntityRoute(): boolean {
    return !!this.$route.params.entity_id
  }

  private get canPerformAction(): boolean {
    return (this.canDeleteQuestion || this.canEditQuestion) && !this.isEntityRoute
  }

  private get canDeleteQuestion(): boolean {
    return this.question.canPerformAction('can_delete_question')
  }

  private get canEditQuestion(): boolean {
    return this.question.canPerformAction('can_edit_question')
  }

  private get canAnswerQuestion(): boolean {
    return this.question.canPerformAction('can_answer_question')
  }

  private get canSeeAnswer(): boolean {
    return !!this.question.answer
  }

  private toggleAnswer(): void {
    this.isAnswerOpen = !this.isAnswerOpen
    this.isEditing = false
  }

  private openAnswer(): void {
    this.isAnswerOpen = true
  }

  private closeAnswer(): void {
    this.isAnswerOpen = false
  }

  private async deleteQuestion(): Promise<void> {
    this.isDeleting = true
    try {
      await this.question.delete()
      this.$emit('delete', this.question)
    } finally {
      this.isDeleting = false
    }
  }

  private closeUpdateQuestionModal() {
    this.updateQuestionForm.isOpen = false
    this.updateQuestionForm.form = new QuestionPatchRequest(this.question.question)
  }

  private openUpdateQuestionModal() {
    this.updateQuestionForm.isOpen = true
    this.updateQuestionForm.form = new QuestionPatchRequest(this.question.question)
  }

  private updateQuestion() {
    if (!this.updateQuestionForm.form?.isValid) return

    this.updateQuestionForm.isSubmitting = true
    try {
      this.question.patch(this.updateQuestionForm.form)
      this.closeUpdateQuestionModal()
    } catch (e) {
      if (e.errors) {
        this.updateQuestionForm.errors = e.errors
      }
    } finally {
      this.updateQuestionForm.isSubmitting = false
    }
  }

  private updateAnswer() {
    this.isEditing = true
  }

  private answered() {
    this.isEditing = false
    this.$emit('refresh')
  }
}
